import conformModal from "./../components/confirm";
import appealModal from "./../components/appeal.vue";
import { detail } from "./api";
import { confirm, appeal } from "./../api.js";
import viewProcess from "./../components/viewProcess"
export default {
  components: { conformModal, appealModal,viewProcess },
  data() {
    return {
      visible: false,
      visibleAppeal: false,
      breadcrumbData: [{
        path: "/index",
        name: "index",
        title: "首页"
      },
        {
          path: "/assessmentNotice",
          name: "考核通知单列表",
          title: "考核通知单列表"
        },
        {
          path: "/assessmentNotice/detail",
          name: "",
          title: "考核通知单详情"
        }
      ],
      navList: [],
      pageLoadFlag: false,
      data: [],
      datass:[],
      columns: [
        {
          title: "序号",
          key: 1,
          dataIndex: "xuhao",
          align: "center"

        },
        {
          title: "品类",
          key: 2,
          dataIndex: "shopTypeName",
          align: "center"
        },
        {
          title: "比例（%）",
          key: 3,
          align: "center",
          dataIndex: "proportion"
        },
        {
          title: "扣除金额(元)",
          key: 4,
          dataIndex: "deductionAmount",
          align: "center"
        },
        {
          title: "对应大写金额",
          key: 5,
          dataIndex: "capitalize",
          align: "center"
        }
      ],
      columnsSS: [
        {
          label: "序号",
          key: 1,
          prop: "xuhao",
          align: "center",
          width:100
        },
        {
          label: "考核类型",
          key: 2,
          prop: "noticeTypeName",
          align: "center",
          width:160
        },
        {
          label: "申诉减免缺口*",
          key: 3,
          align: "center",
          prop: "appealDecreaseCount",
          width:160
        },
        {
          label: "申诉减免金额(元)",
          key: 4,
          prop: "appealDecreaseMoney",
          align: "center",
          width:180
        },
        {
          label: "申诉原因*",
          key: 5,
          prop: "appealReason",
          align: "center",
          width:500
        },
        {
          label: "同意减免缺口",
          key: 6,
          prop: "agreeDecreaseCount",
          align: "center",
          width:160
        },
        {
          label: "证明文件",
          key: 7,
          prop: "Img",
          align: "center",
          slot: { customRender: "Img" },
          width:260
        }
      ],
      detail: {},
      price: 0, // 单价
      assessMoney: 0,
      differenceCount: 0,
      money: 0,
      noticeStatusName: "",
      type: "",
      status:'',
      visibleprocess:false,
      scObj:[],// 市场跑动数据
      fxObj:[],// 分销网络
      cxObj:[],// 促销活动
      pxObj:[],// 培训实施
      MarketTableList1:[],
      year:'',
      mounth:'',
    };
  },
  mounted() {
    this.getdetailInfo();
    // if (this.$route.query.noticeStatusName) {
    //     this.noticeStatusName = this.$route.query.noticeStatusName
    // }
  },
  methods: {
    gotoUrl(row){
      window.open(row.url,'_blank')
    },
    // 打开流程弹窗
    openLiucheng(rowId, row) {
      this.status =''
      this.visibleprocess = true
      this.status = this.detail.appealSpStatus
    },
    // 转换时间
    formatDate(value) {
      if (typeof (value) == "undefined") {
        return "";
      } else {
        let date = new Date(parseInt(value));
        let y = date.getFullYear();
        let MM = date.getMonth() + 1;
        MM = MM < 10 ? ("0" + MM) : MM;
        let d = date.getDate();
        d = d < 10 ? ("0" + d) : d;
        let h = date.getHours();
        h = h < 10 ? ("0" + h) : h;
        let m = date.getMinutes();
        m = m < 10 ? ("0" + m) : m;
        let s = date.getSeconds();
        s = s < 10 ? ("0" + s) : s;
        return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
      }
    },
    goBack() {
      window.close()
    },
    // 获取详情
    getdetailInfo() {

      let data = this.$route.query.id;
      this.pageLoadFlag = true
      detail(data).then(res => {
        if(res.data.code == 0) {
          this.detail = res.data.data;
          this.year = this.detail.month?this.detail.month.split('-')[0]:''
          this.mounth = this.detail.month?this.detail.month.split('-')[1]:''
          this.assessMoney = this.detail.actualAssessMoney;
          this.differenceCount = this.detail.actualAssessCount;
          this.MarketTableList1 =  this.detail.noticeList
          this.detail.noticeList.forEach(item=>{
            if(item.noticeType == 'SCPD') {
              this.scObj.push(item)
            }
            if(item.noticeType == 'FXWLTZ') {
              this.fxObj.push(item)
            }
            if(item.noticeType == 'CXHD') {
              this.cxObj.push(item)
            }
            if(item.noticeType === 'PXSS') {
              this.pxObj.push(item)
            }

          })
          this.type = this.detail.noticeTypeName;
          this.data = this.detail.details;
          this.noticeStatusName = this.detail.noticeStatus;
          if (this.detail.details && this.detail.details.length > 0) {
            this.data.forEach((item, index) => {
              item.xuhao = index + 1;
              // item.proportion = (item.proportion)+'%'
            });
          }
          this.datass = this.detail.appealList;
          if (this.detail.appealList && this.detail.appealList.length > 0) {
            this.datass.forEach((item, index) => {
              item.xuhao = index + 1;
            });
          }
        } else {
          this.$message.warning('获取详情失败,',res.data.msg)
        }

        this.pageLoadFlag = false
      });

    },
    changeStatus() {
      this.visibleAppeal = true;
    },

    confirm1() {
      let data = this.$route.query.id;
      confirm(data).then(res => {
        if (res.data.type == "success") {
          this.$message.success("确认成功," + res.data.text);
          window.opener.location.reload();
          this.timer = setTimeout(()=>{  //设置延迟执行
            window.close()
          },2000);
        } else {
          this.$message.warning("确认失败," + res.data.text);
        }
        this.getdetailInfo();
      });
    },
    appealConfirm($event) {
      let sumData = $event
      // 获取出来对应的附件id
      sumData.forEach(item=>{
        item.fileIds = []
        item.fileList.forEach(items2=>{
          item.fileIds.push(items2.id)
        })
      })
      let appealList = []
      sumData.forEach(dataItem=>{
        appealList.push({
          appealDecreaseCount:dataItem.grievanceGap,
          appealReason:dataItem.reasonAppeal,
          appealFile:dataItem.fileIds.join(','),
          id:dataItem.matklName,
        })
      })
      let data = {
        id: this.$route.query.id,
        appealList: appealList,
      };
      appeal(data).then(res => {
        if (res.data.type == "success") {
          this.$message.success("申诉成功," + res.data.text);
          window.opener.location.reload();
          this.timer = setTimeout(()=>{  //设置延迟执行
            window.close()
          },2000);
        } else {
          this.$message.warning("申诉失败," + res.data.msg);

        }
        this.getdetailInfo()

      });
    }
  }
};